import React from 'react';
import { StyledMenu } from './burgerMenu.styles';
import { Link }from 'react-router-dom';

const Menu = ({open, setOpen}) => {
  //setOpen - took it out
  return (
    <StyledMenu open={open}>
      <div className="topNav" open={open} onClick={() => setOpen(!open)}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon nav-icon primary icon-close"><path class="secondary" fill-rule="evenodd" d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"/></svg>
      </div>
      <div className='bottomNav'>
      <Link to="/">About us</Link>
      {(process.env.REACT_APP_PRODUCTION === undefined || process.env.REACT_APP_PRODUCTION === 'false') && 
      <>
      <Link to="/program">Health & Safety Program Development</Link>
      <Link to="/wcb-certified">Approved By NS WCB Safety Certified</Link>
      <Link to="/insurance">Insurance</Link>
      <Link to="/resources">Resources</Link>
      <Link to="/online">Online Training</Link>
      <Link to="/classroom">Classroom Training</Link>
      </>
      }
      {(process.env.REACT_APP_PRODUCTION === undefined || process.env.REACT_APP_PRODUCTION === 'false') && 
      <>
      <Link to="/admin">Admin</Link>
      <Link to="/user">User</Link>
      </>
      }
      </div>
      
    </StyledMenu>
  )
}
export default Menu;