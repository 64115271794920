import { useState } from "react";
import {useNavigate} from 'react-router-dom';
import { httpLoginUser } from "../Hooks/user.hooks";
import AuthImage from '../Visuals/Images/Authentication.jpg';
import Auth from '../Helpers/auth';
import { AuthContainer, PictureSide, AuthenticationSide, EachInput, AuthContainer2  } from "./auth.styles";
import PrimaryButton from "../Components/Buttons/primaryButton.component";
import TertiaryButton from "../Components/Buttons/tertiaryButton.component";

const LoginComponent = ({setPage}) => {
    const navigate = useNavigate();
    const [showPass, setShowPass] = useState(false);
    const [formState, setFormState] = useState({
        email: '',
        password: '',
    });
    const [error, setError] = useState('');

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormState({
            ...formState,
            [name]: value,
        });
        if(error.length){
            setError('');
        }
    };

    const forgotEmail = (e) => {
        e.preventDefault();
        setError('Needs to be implemeneted still')
    }

    const handleFormSubmit = async (e) => {
       e.preventDefault();
      
       if(formState.email.length < 6 || !formState.email.includes('@') ){
        setError('Not a valid email');
        return;
       }
      if(formState.password.length < 6){
        setError('Password is not valid');
        return;
       }

       const response = await httpLoginUser(formState);
       const data = await response.json();

       if (response.ok) {
         Auth.login(data.token);
        } else {
          setError('Login unsucessful, check credentials and try again');
          return;
        }
        navigate('/user')
        setFormState({
            email: '',
            password: '',
      }); 
    };
    return ( 
       <AuthContainer>
        <AuthContainer2 >
        <PictureSide className="boxShadow">
        <img src={AuthImage} alt="Yellow Helmets" />
        </PictureSide>
        <AuthenticationSide className="boxShadow">
            <form onSubmit={handleFormSubmit}>
             <h2>Login</h2>
            <EachInput>
            <label>Email</label>
            <input type="text" placeholder="johndoe@yoda.com" value={formState.email} name='email' onChange={handleChange} required/> 
            </EachInput>
            <EachInput>
            <div>
            <label>Password</label>
            <br />
            <span className="passwordSwitch" onClick={(e) => {
                e.preventDefault();
                setShowPass(prev => !prev)}}>{!showPass ? "Show password" : "Hide password"}</span>
            </div>
            <input type={!showPass ? "password" : "text"} value={formState.password} name='password' onChange={handleChange} required/> 
            </EachInput>         
            {error && <p className="error">{error}</p> }  
            <PrimaryButton span="Login" />
            </form>
            <div className="outerSwitch">
            <div className="switchPage" style={{paddingInline: '1rem'}}>
            <p style={{fontWeight: "800"}}> Dont Have An Account?</p>
            <TertiaryButton functionName={() => setPage(false)} span="Sign Up" />
            </div>
            <div className="switchPage" style={{paddingInline: '1rem'}}>
            <p>Forgot your password?</p>
            <TertiaryButton functionName={forgotEmail} span="reset password" />
            </div>
            </div>
        </AuthenticationSide>
        </AuthContainer2>
       </AuthContainer>
     );
}
 
export default LoginComponent;