import { useState } from "react";
import { httpSignUpUser} from "../Hooks/user.hooks";
import {useNavigate} from 'react-router-dom';
import Auth from '../Helpers/auth';
import AuthImage from '../Visuals/Images/Authentication.jpg';
import { AuthContainer, PictureSide, AuthenticationSide, EachInput, AuthContainer2  } from "./auth.styles";
import PrimaryButton from "../Components/Buttons/primaryButton.component";
import TertiaryButton from "../Components/Buttons/tertiaryButton.component";

const SignUpComponent = ({setPage}) => {
    const navigate = useNavigate();
    const [showPass, setShowPass] = useState(false);
    const [verifyShowPass, setVerifyShowPass] = useState(false);
    const [error, setError] = useState('');
    const [formState, setFormState] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        verifyPassword: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormState({
            ...formState,
            [name]: value,
        });
        if(error.length){
            setError('');
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        
        if(formState.firstName.length < 2){
            setError('First Name must be longer than 2 characters');
            return;
        }
        if(formState.lastName.length < 3){
            setError('Last Name must be longer than 3 characters');
            return;
        }
        if(formState.email.length < 6 || !formState.email.includes('@') ){
            setError('Not a valid email');
            return;
        }
        if(formState.password.length < 6){
            setError('Password is not long enough');
            return;
        }
        if(formState.password !== formState.verifyPassword){
            setError('Passwords do not match');
            return;
        }

        const response = await httpSignUpUser(formState);
        const data = await response.json();
        
        if (response.ok) {
            try{
                Auth.login(data.token);
            } catch(err){
                setError('Your account has not been created');
                return;
            }
            navigate('/user');
         } else {
           setError('Your account has not been created')
        }
         setFormState({
            firstName: '',
            lastName: '',
            email: '',
            password: '',
       });
    };
    return ( 
        <AuthContainer>
        <AuthContainer2>
        <PictureSide className="boxShadow">
        <img src={AuthImage} alt="Yellow Helmets" />
        </PictureSide>
        <AuthenticationSide className="boxShadow">
        <form onSubmit={handleFormSubmit}>
            <h2>Sign Up</h2>
            <div className="nameDiv">
            <EachInput>
            <label>First Name</label>
            <input type="text" value={formState.firstName} name='firstName' onChange={handleChange} required />
            </EachInput>
            <EachInput>
            <label>Last Name</label>
            <input type="text" value={formState.lastName} name='lastName' onChange={handleChange} required />
            </EachInput>
            </div>
            <EachInput>
            <label>Email</label>
            <input type="text" value={formState.email} name='email' onChange={handleChange} required/>
            </EachInput>
            {/* Password */}
            <EachInput>
            <div>
            <label>Password</label>
            <span className="italicSpan" style={{paddingLeft: '1rem'}}>Must be at least 6 characters </span>
            <br />
            <span className="passwordSwitch" onClick={(e) => {
                e.preventDefault();
                setShowPass(prev => !prev)}}>{!showPass ? "Show password" : "Hide password"}</span>
            </div>
            <input type={!showPass ? "password" : "text"} value={formState.password} name='password' onChange={handleChange} required/>
            </EachInput>
            {/* Verify Password */}
            <EachInput>
            <div>
            <label>Verify Password</label>
            <span className="passwordSwitch" onClick={(e) => {
                e.preventDefault();
                setVerifyShowPass(prev => !prev)}}>{verifyShowPass ? "Show password" : "Hide password"}</span>
            </div>
            <input type={verifyShowPass ? "password" : "text"} value={formState.verifyPassword} name='verifyPassword' onChange={handleChange} required/>
            </EachInput>
            {error && <p className="error">{error}</p>}
            <PrimaryButton span="Sign Up" />
            <div className="switchPage">
            <p style={{fontWeight: "800"}}>Have An Account?</p>
            <TertiaryButton functionName={() => setPage(true)} span="Log In" />
            </div>
            </form>
        </AuthenticationSide>
        </AuthContainer2>
        </AuthContainer>
     );
}
 
export default SignUpComponent;